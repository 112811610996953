/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { AuthenticationManagerContext, DHLIFrame, DHLPagetemplate, trackPageCall } from "@gkuis/gkp-base-widgets";
import "./LoginPageRoot.scss";
import { useTranslation } from "react-i18next";
import { reaction } from "mobx";
import moment from "moment";
import { InternalLoginForm } from "../InternalLoginForm/InternalLoginForm";
import { ExternalLogin } from "../ExternalLogin/ExternalLogin";

export type LoginPageRootProps = {
  mode: "internal" | "external";
  authenticationManager: AuthenticationManager;
  cmsContentRoot?: string;
}

const loginHeaderPath = "geschaeftskunden/portal/kundendesktop/oeffentlich/startseite/banner.data.with-stage.html";
const loginFooterPath = "geschaeftskunden/portal/kundendesktop/oeffentlich/startseite/teaser-faq.data.html";
const contactFormPath = "geschaeftskunden/portal/admindesktop/formulare/kontaktformular-intern-d391ed.data.html";

export const LoginPageRoot = observer((
    {
      mode,
      authenticationManager,
      cmsContentRoot = ""
    }: LoginPageRootProps
) => {
  const {i18n} = useTranslation();
  i18n.setDefaultNamespace(mode);

  useEffect(() => reaction(
      () => authenticationManager.language,
      (language) => {
        i18n.changeLanguage(language);
        moment.locale(language.startsWith("en") ? "en-gb" : "de");
      },
      {fireImmediately: true}
  ), [authenticationManager, i18n]);

  useEffect(() => trackPageCall("GKP:Anmeldeseite"), []);

  if (authenticationManager.authenticatedSubject !== undefined) {
    return null;
  }

  return (
      <AuthenticationManagerContext.Provider value={authenticationManager}>
        <DHLPagetemplate className={"loginPageRoot"}>
          {mode === "external" && <DHLIFrame
              className={"cmsContentContainer"}
              url={`${cmsContentRoot}/${authenticationManager.language}/${loginHeaderPath}`}
              title={"LoginHeader"}
              resizeToEventHeight={true}
              resizeToViewportWidth={true}
              height={444}
          />}
          {mode === "external" && <ExternalLogin />}
          {mode === "internal" && <InternalLoginForm />}
          {mode === "internal" && authenticationManager.language === "de" && <DHLIFrame
              className={"cmsContentContainer"}
              url={`${cmsContentRoot}/${authenticationManager.language}/${contactFormPath}`}
              title={"ContactForm"}
              resizeToEventHeight={true}
              resizeToViewportWidth={true}
              height={1054} />}
          {mode === "external" && <DHLIFrame
              className={"cmsContentContainer"}
              url={`${cmsContentRoot}/${authenticationManager.language}/${loginFooterPath}`}
              title={"LoginFooter"}
              resizeToEventHeight={true}
              resizeToViewportWidth={true}
              height={1148}
          />}
        </DHLPagetemplate>
      </AuthenticationManagerContext.Provider>
  );
});