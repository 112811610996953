/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import {
  DHLFormGroup,
  DHLHeadline,
  DHLMessages,
  DHLSubmitButton,
  DHLTextOutput,
  MessageDataStore,
  useAuthenticationManager
} from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./InternalLoginForm.scss";
import { useState } from "react";

export const InternalLoginForm = observer(() => {
  const {t} = useTranslation();
  const [messageDataStore] = useState(() => new MessageDataStore());
  const authenticationManager = useAuthenticationManager();

  return (
      <div className={classNames("internal-login-module-container")}>
        <div className={classNames("offset-4", "col-4", "no-gutters")}>
          {messageDataStore.getMsgs().length > 0 && <DHLFormGroup>
            <DHLMessages name={"login-messages"} msgStore={messageDataStore} />
          </DHLFormGroup>
          }
          <div className={classNames("login-module-form")}>
            <DHLHeadline type={"h4"}>{t("headline")}</DHLHeadline>
            <DHLFormGroup>
              <DHLTextOutput value={t("hint.canUseInternalUser")} />
            </DHLFormGroup>
            <DHLFormGroup>
              <DHLSubmitButton
                  label={t("login.button.label")}
                  name={"loginSubmit"}
                  onClick={() => authenticationManager.login()} />
            </DHLFormGroup>
            <DHLTextOutput value={t("hint.howToGetInternalUser")} />
          </div>
        </div>
      </div>
  );
});
