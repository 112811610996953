/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { DHLButton, DHLFormGroup, DHLHeadline, useAuthenticationManager } from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./ExternalLogin.scss";

export const ExternalLogin = observer(() => {
  const {t} = useTranslation();
  const authenticationManager = useAuthenticationManager();

  return (
      <div className={classNames("login-module-container")}>
        <div className={classNames("login-wrapper")}>
          <DHLHeadline type={"h4"}>{t("headline")}</DHLHeadline>
          <DHLFormGroup>
            <div className={"dhlTextOutputContainer loginHint"}>
              <p className={"dhlTextOutput"} dangerouslySetInnerHTML={{__html: t("hint.optimizingLogin", {interpolation: {escapeValue: false}})}} />
            </div>
          </DHLFormGroup>
          <DHLFormGroup>
            <DHLButton
                icon={"header-login"}
                iconPosition={"icon-first"}
                label={t("login.button.label")}
                tooltip={t("login.button.tooltip")}
                type={"primary"}
                onClick={() => authenticationManager.login()} />
          </DHLFormGroup>
        </div>
      </div>
  );
});
